









































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextAreaWithOneModeText from '@/components/TextAreaWithOneModeText.vue'
import {
  GroupDetail,
  ENameColor,
  EAvatarType,
  EEditMode,
  ETinyFileType
} from '@/models'
import scrollToError from '@/helpers/ScrollToError'
import GroupService from '@/services/GroupService'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'
//@ts-ignore
import _ from 'lodash'
import updateGroupMaxFileSize from '@/helpers/UpdateGroupMaxFileSize'
import { Area } from '@/models/GroupTag/area'
import GroupTagService from '@/services/GroupTagService'
import Multiselect from "vue-multiselect";
import { GroupTagTarget } from "@/models/GroupTag/target";
import { EventBus } from "@/event-bus";

/**
 * f2-101a
 * グループ設定　基本情報編集画面
 */
@Component({ components: { TextAreaWithOneModeText, Multiselect } })
export default class GroupInfoCreatePage extends Vue {
  //data
  private groupInfo: GroupDetail = new GroupDetail()
  private groupInfoCurrent: GroupDetail = new GroupDetail() //used for check before leave
  private defaultAvaSrc: IAvatarSrc[] = []
  private defaultAvatarPick: IAvatarSrc = new IAvatarSrc()
  private avatarFullSizeSrc: string = ''
  private avatarFullSizeBlob!: Blob
  private avatarCropperSrc: string = ''
  private chooseAvatarName: string = String(this.$t('common.form.not_selected'))
  private chooseAvatarTempName: string = ''
  private coverSrc: string = ''
  private coverBlob!: File
  private chooseCoverName: string = String(this.$t('common.form.not_selected'))
  private noticeMode: EEditMode = EEditMode.TEXT
  private noticeText: string = ''
  private noGroupAvatar =
    window.location.origin + require('@/assets/images/group/avatar_default_1.png')
  private title: string = ''
  private icon_image: EAvatarType = EAvatarType.FILE_UPLOAD
  private letter_color: ENameColor = ENameColor.WHITE
  private auto_delete_day: number = 30
  private notice: string = ''
  private icon_image_small_path: string = ''
  private icon_file_name: string = ''
  
  //validate
  private modalMess: string = ''
  private imageType: string[] = ['image/png', 'image/gif', 'image/jpeg']
  private errorAvatarTypeAndSize: boolean = false
  private errorCoverTypeAndSize: boolean = false
  private maxFileSize: any = {
    mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
    bytes:
      Number(this.$store.state.userInfo.user.current_group_max_file_size) *
      1048576
  }
  private validNotice: boolean = true

  //enum
  private eNameColor: any = ENameColor
  private eAvatarType: any = EAvatarType
  private eTinyFileType: any = ETinyFileType
  private ehumbNailsDisplay: any = EhumbNailsDisplay

  //hanlde before leave
  private confirmLeave: any = () => {}
  private confirmLeaveIgnore: boolean = false
  private groupTagArea: Area[] = []
  private listArea: any[] = []

  public topic: any = {
    nationwide_id: [],
    province_id: [],
    district_id: [],
    check_all: false,
    group_tag_id: [],
  }
  private selectedValueTargetArea: any[] = []
  private check_group_all: any = 0
  // private check_all: any = ''
  private listAreaProvince: any[] = []
  private listAreaDistrict: any[] = []

  async created() {
    this.importAllDefaultAvatar()
    this.setDefaultForAvatar()
    this.handleMaxFileSize()
    this.getListTagArea() 
  }
  // @Watch('topic.check_all')
  changeCheckAll(e:any) {
    if (this.topic.check_all) {
      this.check_group_all = 1
    } else {
      this.check_group_all = 0
    }
  }
  // checkGroupAll(e: any) {
  //   this.check_group_all = e.target.checked
  //   var listGroupTagArea = this.groupTagArea.map((groupTagArea) => (groupTagArea.id))
  //     var listArea = this.listArea.map((listArea) => (listArea.id))
  //     var listProvince = this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id))
  //     var listDistrict = this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id))
  //   if (this.check_group_all) {
  //     console.log(1);
      
      
  //     this.selectedValueTargetArea = listGroupTagArea
  //     this.topic.nationwide_id = listArea
  //     this.topic.province_id = listProvince
  //     this.topic.district_id = listDistrict
  //     this.check_all = 1
  //   } else {
  //     console.log(2);
      
  //     this.selectedValueTargetArea = []
  //     this.topic.nationwide_id = []
  //     this.topic.province_id = []
  //     this.topic.district_id = []
  //     this.check_all = 0
  //   }
  //   console.log(this.topic.province_id);
  //   console.log(this.topic.district_id);
  // }

  @Watch('topic.group_tag_id')
  watchSelectedValueTargetArea() {
    if (this.topic.group_tag_id.length !== this.groupTagArea.map((groupTagArea) => (groupTagArea.id)).length) {
      this.topic.check_all = false
      this.check_group_all = 0
    } else {
        if (this.topic.district_id.length !== this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id)).length) {
        this.topic.check_all = false
        this.check_group_all = 0
      } else {
        if (this.topic.province_id.length !== this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id)).length) {
          this.topic.check_all = false
          this.check_group_all = 0
        } else {
          if (this.topic.nationwide_id.length !== this.listArea.map((listNation) => (listNation.id)).length) {
            this.topic.check_all = false
            this.check_group_all = 0
          } else {
            this.topic.check_all = true
            this.check_group_all = 1
          }
          this.topic.check_all = true
          this.check_group_all = 1
        }
        this.topic.check_all = true
        this.check_group_all = 1
      }
    }
  }

  @Watch('topic.province_id')
  watchProvinceArea() {
    if (this.topic.province_id.length !== this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id)).length) {
      this.topic.check_all = false
      this.check_group_all = 0
    } else {
      if (this.topic.district_id.length !== this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id)).length) {
        this.topic.check_all = false
        this.check_group_all = 0
      } else {
        if (this.topic.group_tag_id.length !== this.groupTagArea.map((groupTagArea) => (groupTagArea.id)).length) {
          this.topic.check_all = false
          this.check_group_all = 0
        } else {
          if (this.topic.nationwide_id.length !== this.listArea.map((listNation) => (listNation.id)).length) {
            this.topic.check_all = false
            this.check_group_all = 0
          } else {
            this.topic.check_all = true
            this.check_group_all = 1
          }
          this.topic.check_all = true
          this.check_group_all = 1
        }
        this.topic.check_all = true
        this.check_group_all = 1
      }
    }
  }

  @Watch('topic.district_id')
  watchDistrictArea() {
    if (this.topic.district_id.length !== this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id)).length) {
      this.topic.check_all = false
      this.check_group_all = 0
    } else {
      if (this.topic.group_tag_id.length !== this.groupTagArea.map((groupTagArea) => (groupTagArea.id)).length) {
        this.topic.check_all = false
        this.check_group_all = 0
      } else {
        if (this.topic.province_id.length !== this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id)).length) {
          this.topic.check_all = false
          this.check_group_all = 0
        } else {
          if (this.topic.nationwide_id.length !== this.listArea.map((listNation) => (listNation.id)).length) {
            this.topic.check_all = false
            this.check_group_all = 0
          } else {
            this.topic.check_all = true
            this.check_group_all = 1
          }
          this.topic.check_all = true
          this.check_group_all = 1
        }
        this.topic.check_all = true
        this.check_group_all = 1
      }
    }
  }

  // @Watch('topic.nationwide_id')
  // watchArea() {
  //   var listProvince = this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id))
  //   var listDistrict = this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id))
  //   if (this.topic.nationwide_id.length !== this.listArea.map((listArea) => (listArea.id)).length) {
  //     this.check_group_all = false
  //     this.check_all = 0
  //   } else {
  //     this.topic.province_id = listProvince
  //     this.topic.district_id = listDistrict
  //     this.check_group_all = true
  //     this.check_all = 1
  //   }
  // }

  // @Watch('topic.province_id')
  // watchListProvince() {
  //   var listProvince = this.listAreaProvince.map((listAreaProvince) => (listAreaProvince.id))
  //   var listDistrict = this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id))
  //   if (this.topic.province_id.length !== listProvince.length) {
  //     this.check_group_all = false
  //     this.check_all = 0
  //   } else {
  //     this.topic.district_id = listDistrict
  //     this.check_group_all = true
  //     this.check_all = 1
  //   }
  // }

  // @Watch('topic.district_id')
  // watchListDistrict() {
  //   var listDistrict = this.listAreaDistrict.map((listAreaDistrict) => (listAreaDistrict.id))
  //   if (this.topic.district_id.length !== listDistrict.length) {
  //     this.check_group_all = false
  //     this.check_all = 0
  //   } else {
  //     this.check_group_all = true
  //     this.check_all = 1
  //   }
  // }


  async handleMaxFileSize() {
    await updateGroupMaxFileSize(this.$route.params.groupId)
    this.maxFileSize = {
      mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
      bytes:
        Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
    }
  }

  checkRouterBeforLeave() {
    return (
      !this.title &&
      !this.notice &&
      !this.letter_color &&
      !this.icon_image &&
      !this.auto_delete_day &&
      !this.coverBlob &&
      !this.avatarFullSizeBlob
    )
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && !this.checkRouterBeforLeave()) {
      this.confirmLeave = next
      this.$bvModal.show('group-create-modal-confirm-leave')
    } else {
      next()
    }
  }

  @Watch('avatarFullSizeSrc')
  setDefaultForAvatar() {
    if (!this.avatarFullSizeSrc) {
      this.avatarFullSizeSrc = this.noGroupAvatar
    }
  }

  @Watch('icon_image')
  watchUseageAvatar() {
    if (this.icon_image === EAvatarType.NONE) {
      this.avatarFullSizeSrc = ''
      this.chooseAvatarName = ''
    } else {
      this.avatarFullSizeSrc = this.icon_image_small_path
      this.chooseAvatarName = this.icon_file_name
    }
  }

  async getListTagArea() {
    return GroupTagService.getListTagArea()
      .then((response) => {
        if (response.status === 200) {
          this.listArea = response.data.nation;
          this.groupTagArea = response.data.area;
          this.listAreaProvince = response.data.province
          this.listAreaDistrict = response.data.district
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleSelect(event: any) {
    if(event.target.checked) {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if ((value as any)[0]) {
          (value as any)[0].checked = true;

          if (
              (value as any)[0].getAttribute("data-checked-group-tag") &&
              !this.topic.group_tag_id.includes((value as any)[0].value)
            ) {
              this.topic.group_tag_id.push((value as any)[0].value);
            }

          if (
              (value as any)[0].getAttribute("data-checked-nationwide") &&
              !this.topic.nationwide_id.includes((value as any)[0].value)
            ) {
              this.topic.nationwide_id.push((value as any)[0].value);
            }

          if (
            (value as any)[0].getAttribute("data-checked-province") &&
            !this.topic.province_id.includes((value as any)[0].value)
          ) {
            this.topic.province_id.push((value as any)[0].value);
          }

          if (
            (value as any)[0].getAttribute("data-checked-district") &&
            !this.topic.district_id.includes((value as any)[0].value)
          ) {
            this.topic.district_id.push((value as any)[0].value);
          }
        }
      })
    } else {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if ((value as any)[0]) {
          (value as any)[0].checked = false;
        }

        this.topic.group_tag_id = []
        this.topic.nationwide_id = []
        this.topic.province_id = []
        this.topic.district_id = []
      })
    }
  }

  checkboxValueArea($id: string) {
    const [top1, top2, top3] = $id.split("_");

    if (Number(top3)) {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key.includes(`${top1}_${top2}_0`) || key.includes(`${top1}_0_0`)) {
          if ((value as any)[0]) {
            (value as any)[0].checked = true;

            if (
              (value as any)[0].getAttribute("data-checked-nationwide") &&
              !this.topic.nationwide_id.includes((value as any)[0].value)
            ) {
              this.topic.nationwide_id.push((value as any)[0].value);
            }

            if (
              (value as any)[0].getAttribute("data-checked-province") &&
              !this.topic.province_id.includes((value as any)[0].value)
            ) {
              this.topic.province_id.push((value as any)[0].value);
            }

          }
        }

        if (key == "CHECK_ALL") {
          (value as any).checked = false;
        }

      });
    } else if (Number(top2)) {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key.includes(`${top1}_0_0`)) {
          if ((value as any)[0]) {
            (value as any)[0].checked = true;

            if (
              (value as any)[0].getAttribute("data-checked-nationwide") &&
              !this.topic.nationwide_id.includes((value as any)[0].value)
            ) {
              this.topic.nationwide_id.push((value as any)[0].value);
            }
          }
        }

        if (key !== `${top1}_${top2}_0` && key.includes(`${top1}_${top2}_`) || key == "CHECK_ALL") {
          if (key == "CHECK_ALL") {
            (value as any).checked = false;
          }

          if (key != "CHECK_ALL" && (value as any)[0]) {
            (value as any)[0].checked = false;

            if (
              key != "CHECK_ALL" &&
              (value as any)[0].getAttribute("data-checked-district") &&
              this.topic.district_id.length
            ) {
              this.topic.district_id = this.topic.district_id.filter(function (
                item: any
              ) {
                return item != (value as any)[0].value;
              });
            }
          }
        }
      });
    } else {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key !== `${top1}_0_0` && key.includes(`${top1}_`) || key == "CHECK_ALL") {
          if (key == "CHECK_ALL") {
            (value as any).checked = false;
          }

          if (key != "CHECK_ALL" && (value as any)[0]) {
            (value as any)[0].checked = false;
          }

          if (
            key != "CHECK_ALL" &&
            (value as any)[0].getAttribute("data-checked-district") &&
            this.topic.district_id.length
          ) {
            this.topic.district_id = this.topic.district_id.filter(function (
              item: any
            ) {
              return item != (value as any)[0].value;
            });
          }

          if (
            key != "CHECK_ALL" &&
            (value as any)[0].getAttribute("data-checked-province") &&
            this.topic.province_id.length
          ) {
            this.topic.province_id = this.topic.province_id.filter(function (
              item: any
            ) {
              return item != (value as any)[0].value;
            });
          }
        }
      });
    }

  }

  // checkboxValueArea($id: string) {
  //   const [top1, top2, top3] = $id.split("_");

  //   if (Number(top3)) {
  //     Object.entries(this.$refs).forEach(([key, value]) => {
  //       if (key.includes(`${top1}_${top2}_0`) || key.includes(`${top1}_0_0`)) {
  //         if ((value as any)[0]) {
  //           (value as any)[0].checked = true;

  //           if (
  //             (value as any)[0].getAttribute("data-checked-nationwide") &&
  //             !this.topic.nationwide_id.includes((value as any)[0].value)
  //           ) {
  //             this.topic.nationwide_id.push((value as any)[0].value);
  //           }

  //           if (
  //             (value as any)[0].getAttribute("data-checked-province") &&
  //             !this.topic.province_id.includes((value as any)[0].value)
  //           ) {
  //             this.topic.province_id.push((value as any)[0].value);
  //           }
  //         }
  //       }
  //     });
  //   } else if (Number(top2)) {
  //     Object.entries(this.$refs).forEach(([key, value]) => {
  //       if (key.includes(`${top1}_0_0`)) {
  //         if ((value as any)[0]) {
  //           (value as any)[0].checked = true;

  //           if (
  //             (value as any)[0].getAttribute("data-checked-nationwide") &&
  //             !this.topic.nationwide_id.includes((value as any)[0].value)
  //           ) {
  //             this.topic.nationwide_id.push((value as any)[0].value);
  //           }
  //         }
  //       }

  //       if (key !== `${top1}_${top2}_0` && key.includes(`${top1}_${top2}_`)) {
  //         if ((value as any)[0]) {
  //           (value as any)[0].checked = false;

  //           if (
  //             (value as any)[0].getAttribute("data-checked-district") &&
  //             this.topic.district_id.length
  //           ) {
  //             this.topic.district_id = this.topic.district_id.filter(function (
  //               item: any
  //             ) {
  //               return item != (value as any)[0].value;
  //             });
  //           }
  //         }
  //       }
  //     });
  //   } else {
  //     Object.entries(this.$refs).forEach(([key, value]) => {
  //       if (key !== `${top1}_0_0` && key.includes(`${top1}_`)) {
  //         if ((value as any)[0]) {
  //           (value as any)[0].checked = false;
  //         }

  //         if (
  //           (value as any)[0].getAttribute("data-checked-district") &&
  //           this.topic.district_id.length
  //         ) {
  //           this.topic.district_id = this.topic.district_id.filter(function (
  //             item: any
  //           ) {
  //             return item != (value as any)[0].value;
  //           });
  //         }

  //         if (
  //           (value as any)[0].getAttribute("data-checked-province") &&
  //           this.topic.province_id.length
  //         ) {
  //           this.topic.province_id = this.topic.province_id.filter(function (
  //             item: any
  //           ) {
  //             return item != (value as any)[0].value;
  //           });
  //         }
  //       }
  //     });
  //   }
  // }

  importAllDefaultAvatar() {
    GroupService.getGroupDefaultIcon().then(res => {
      if (res.status === 200) {
        this.defaultAvaSrc = res.data
      }
    })
  }

  selectDefaultAvatar(pickedAvatar: IAvatarSrc) {
    this.avatarFullSizeSrc = pickedAvatar.small_path
    this.defaultAvatarPick = pickedAvatar
  }

  onAvatarChange(event: any) {
    let files = event.target.files || event.dataTransfer.files
    if (event.target.files.length !== 1) return

    //error file size
    if (files[0].size > this.maxFileSize.bytes) {
      this.modalMess = this.$t('groups.shared_folder.overwrite_error_msg', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show('modal-info-group-create')
      this.errorAvatarTypeAndSize = true
      return
    }

    //error file type
    if (!this.imageType.includes(files[0].type)) {
      this.modalMess = this.$t('common.message.upload_image_only') as string
      this.$bvModal.show('modal-info-group-create')
      this.errorAvatarTypeAndSize = true
      return
    }

    //normal case
    this.errorAvatarTypeAndSize = false
    const blob = new Blob([files[0]])
    this.avatarCropperSrc = URL.createObjectURL(blob)
    this.chooseAvatarTempName = files[0].name
    this.$bvModal.show('cropAvatar')
  }

  onCoverChange(event: any) {
    let files = event.target.files || event.dataTransfer.files
    if (event.target.files.length !== 1) return

    //error file size
    if (files[0].size > this.maxFileSize.bytes) {
      this.modalMess = this.$t('groups.shared_folder.overwrite_error_msg', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show('modal-info-group-create')
      this.errorCoverTypeAndSize = true
      return
    }

    //error file type
    if (!this.imageType.includes(files[0].type)) {
      this.modalMess = this.$t('common.message.upload_image_only') as string
      this.$bvModal.show('modal-info-group-create')
      this.errorCoverTypeAndSize = true
      return
    }

    //normal case
    this.errorCoverTypeAndSize = false
    const blob = new Blob([files[0]])
    this.coverSrc = URL.createObjectURL(blob)
    this.coverBlob = files[0]
    this.chooseCoverName = files[0].name
  }

  handleCropperImg() {
    const canvas = (this.$refs.avatarCropper as any).clip()
    canvas.toBlob((blob: Blob) => {
      this.avatarFullSizeSrc = URL.createObjectURL(blob)
      this.avatarFullSizeBlob = blob
      this.chooseAvatarName = this.chooseAvatarTempName
      this.$bvModal.hide('cropAvatar')
    })
  }

  handleValueNotice(value: any) {
    if (value.mode === EEditMode.TEXT) {
      this.notice = value.text
    } else {
      this.notice = value.html
    }
    this.noticeText = value.text
  }

  handleValidNotice(valid: boolean) {
    this.validNotice = valid
  }

  async onSubmitGroupInfo(valid: boolean) {
    console.log(this.topic.check_all);
    console.log(this.check_group_all);
    
    if (
      valid &&
      !this.errorAvatarTypeAndSize &&
      !this.errorCoverTypeAndSize &&
      this.validNotice
    ) {
      this.confirmLeaveIgnore = true
      let formData = new FormData()
      formData.set('name', this.title)
      formData.set('icon_image', String(this.icon_image))
      formData.set('icon_file_name', this.chooseAvatarName)
      formData.set('letter_color', String(this.letter_color))
      formData.set('notice', this.notice)
      formData.set('auto_delete_day', String(this.auto_delete_day))
      if (this.coverBlob) {
        formData.set('header_image_blob', this.coverBlob)
        formData.set('header_file_name', this.chooseCoverName)
      }
      if (this.topic.check_all) {
        formData.set('area_all', this.check_group_all)
      } else {
        formData.set('target_area', String(this.topic.group_tag_id))
        formData.set('nationwide_id', String(this.topic.nationwide_id))
        formData.set('province_id', String(this.topic.province_id))
        formData.set('district_id', String(this.topic.district_id))
      }
      this.handleIconImageChosse(formData)
      // console.log(...formData)
      formData.append('_method', 'POST')
      this.$blockui.show()
      GroupService.createGroupBasicInfo(formData)
        .then(res => {
          console.log(res)
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.registered') as string
            this.$bvModal.show('modal-success')
          }
        })
        .catch((err) => {
          console.log(err)
          this.modalMess = this.$t('common.message.register_fail') as string
          this.$bvModal.show('modal-error')
        })
        .finally(() => this.$blockui.hide())
    } else {
      console.log(3)
      scrollToError(this.$refs, 'groupBasicInfo')
    }
  }

  /**
   * Hanle group icon
   */
  handleIconImageChosse(formData: FormData) {
    switch (this.icon_image) {
      case EAvatarType.FILE_UPLOAD:
        if (this.avatarFullSizeBlob)
          formData.set('icon_image_blob', this.avatarFullSizeBlob)
        break
      case EAvatarType.DEFAULT:
        if (this.icon_image_small_path !== this.avatarFullSizeSrc) {
          formData.set('icon_image_path', this.defaultAvatarPick.path)
          formData.set(
            'icon_image_small_path',
            this.defaultAvatarPick.small_path
          )
        }
        break
      case EAvatarType.NONE:
        formData.set('icon_image_path', this.noGroupAvatar)
        formData.set('icon_image_small_path', this.noGroupAvatar)
        break
      default:
        break
    }
  }

  backToInfoPage() {
    this.$router.push({ name: 'home' })
  }

  backToInfoPageRoute() {
    return ({ name: 'home' })
  }
}

class IAvatarSrc {
  id!: number
  name: string = ''
  path: string = ''
  small_path: string = ''

  constructor(init?: Partial<IAvatarSrc>) {
    Object.assign(this, init)
  }
}
